// import { drawerEmits } from "element-plus"
import { DataLine } from "@element-plus/icons"
import request from "../request"

export default {

    getSmileInfo: (data) => {
        return request.post({
            url: '/service/basic_info',
            data: data,
        })
    },
    getModelInfo: (data) => {
        return request.get({
            url: '/service/model_info?mode=' + data.mode,
            data: data,
        })
    },
    getActivityScore: (data) => {
        return request.post({
            url: '/service/predict_without_file/' + data.mode,
            data: {
                smiles: data.smiles,
                title: data.title,
            },
        })
    },
    getDetailModelInfo: (data) => {
        return request.get({
            url: '/service/model_detail?mode=' + data.mode + '&model=' + data.model,
            data: data,
        })
    }
}