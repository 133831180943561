<template>
    <div class="popper">
        <form class="content">
            <header>
                Confirm Information
                <button id="cancel-btn" @click="this.$emit('onCancel')">
                    <el-icon class="close-icon">
                        <close-bold />
                    </el-icon>
                </button>
            </header>
            <div class="infos">
                <div class="info-item" v-for="(value, key) in infos" :key="key">
                    <div class="key">{{ key }}</div>
                    <p class="maohao">&nbsp;:&nbsp;</p>
                    <p class="value" :title="value">{{ value }}</p>
                </div>
                <div class="info-item">
                    <label class="key" for="task-name">Task Name</label>
                    <p class="maohao">&nbsp;:&nbsp;</p>
                    <div class="value">
                        <input type="text" id="task-name" v-model="taskName" />
                    </div>
                </div>
                <!-- <div class="info-item">
                    <label class="key" for="email">Email</label>
                    <p class="maohao">&nbsp;:&nbsp;</p>
                    <div class="value">
                        <input type="text" id="email" v-model="email" required />
                    </div>
                </div> -->
            </div>

            <footer>
                <button class="submit-btn" @click.prevent="onSubmit">Submit</button>
            </footer>
        </form>
    </div>
</template>

<script>
export default {
    props: ["infos"],
    data() {
        return {
            taskName: "",
            // email: null,
        };
    },
    methods: {
        onSubmit() {
            // e.preventDefalut();
            // if (this.email) this.$emit("onSubmit", this.taskName, this.email);
            this.$emit("onSubmit", this.taskName);
        },
    },
};
</script>

<style scoped>
.popper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.2);
    color: black;
}

.content {
    width: 600px;
    background: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 2px 2px 5px var(--color2-dark);
}

header {
    position: relative;
    font-size: 20pt;
    font-weight: bold;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    height: 45px;
    line-height: 45px;
    text-align: center;
    background: linear-gradient(to right,
            var(--color2-dark),
            var(--color2-light));
}

#cancel-btn {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    outline: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: var(--color2-light);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

#cancel-btn:hover {
    background: white;
}

.infos {
    width: 100%;
}

.info-item:nth-child(2n) {
    background: var(--color2-light);
}

.info-item {
    display: flex;
    /* margin: 10px 0; */
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    font-size: 16pt;
    padding: 0 50px;
    /* background: pink; */
}

.info-item .key {
    width: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    /* padding-left: 30px; */
}

.info-item .value {
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 30px;
    display: flex;
    align-items: center;
}

.maohao {
    display: flex;
    align-items: center;
}

#task-name {
    height: 30px;
    width: 200px;
    border: none;
    outline: none;
    font-size: 16pt;
    border: 2px solid var(--color2-light);

    /* border-bottom: 1px solid var(--color2-dark); */
    /* background: white; */
}

footer {
    font-size: 26px;
    display: flex;
    height: 60px;
    justify-content: space-around;
    width: 100%;
}

.submit-btn {
    margin: 10px 0;
    font-size: 18pt;
    /* padding: 0 5px; */
    width: 200px;
    border-radius: 10px;
    border: none;
    outline: none;
    background: linear-gradient(to right,
            var(--color2-dark),
            var(--color2-light));
    cursor: pointer;
}

.submit-btn:hover {
    background: linear-gradient(to left, var(--color2-dark), var(--color2-light));
}

.el-icon {
    font-size: 12pt;
}
</style>