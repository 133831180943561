<template>
  <div class="rowcenter background">
    <div class="columncenter">
      <img class="backgroundimg" src="../assets/singleprediction/background.png" />
      <form id="uploadform" enctype="multipart/form-data">
        <input type="file" name="upload" id="uploadinput" @change="submitform" style="display: none" />
      </form>
      <div style="z-index: 1" class="inputs">
        <div class="rowcenter" style="width: 100%">
          <div class="rowcenter" style="position: relative; width: 615px">
            <div style="
                position: absolute;
                left: 0;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
              ">
              <img src="../assets/singleprediction/arrow2.png" style="height: 20pt; transform: rotate(180deg)"
                @click="gotosingle" />
            </div>
            <h1 style="font-size: 32pt; color: white">Batch Predicting</h1>
          </div>
        </div>
        <div class="rowcenter">
          <div class="text-area">
            <textarea class="input1"
              placeholder="Please enter mutiple molecula fomulas seperated with space or carriage return or upload file instead."
              v-model="molecularFormula" @input="ifinput" id="mfinput"></textarea>

            <el-button class="text-area-btn" v-if="ifshow" style="margin-right: 90px" type="text"
              @click="downloadExample">
              <el-icon>
                <download />
              </el-icon>
              <div style="margin-left: 9px; font-size: 10pt">
                Example
              </div>
            </el-button>
            <el-button class="text-area-btn" v-if="ifshow" style="margin-right: 16px" @click="uploadfile" type="text">
              <el-icon>
                <upload />
              </el-icon>
              <div style="margin-left: 0px; font-size: 10pt">
                Upload
              </div>
            </el-button>
          </div>
        </div>
        <div class="radios">
          <div class="input" v-for="item in modeArr" :key="item.mode">
            <input :id="item.mode" type="radio" name="radio" @click="setmode(item.mode)" /><label class="text"
              @click="setmode(item.mode)" :for="item.mode">{{ item.name }}</label>
          </div>
        </div>
        <div class="rowcenter">
          <img style="width: 1006px; margin-top: 38px" src="../assets/singleprediction/photo1.png" />
        </div>
        <div class="rowcenter">
          <el-button class="button1" onmouseover="this.className='button1'" onmouseout="this.className='button'"
            @click="onSubmit">
            Submit
          </el-button>
        </div>
      </div>
    </div>
    <predict-popper v-show="showPopper" :infos="popperInfo" @onSubmit="onSubmitPopper" @onCancel="onCancelPopper">
    </predict-popper>
  </div>
</template>

<script>
import { BatchApi } from "../api/index";
import popper from "../components/popper/popper";

export default {
  name: "Batchinput",
  data() {
    return {
      molecularFormula: "",
      smilesFile: null,
      score: {},
      mode: "virus_cell",
      filename: "",
      modelinfo: {},
      ifshow: 1,
      smilesinfo: {},
      predictmode: 0, // 批量预测的模式 0为输入多个分子式进行预测 1为直接上传分子式的文档进行预测
      modeArr: [
        { name: "Cancer Cell Lines", mode: "cancer_cell" },
        { name: "NCI60 Cancer Cells", mode: "nci60_cancer_cell" },
        { name: "Virus Target", mode: "virus_target" },
        { name: "Virus Cell Lines", mode: "virus_cell" },
        { name: "Anti-cancer Targets", mode: "cancer_target" },
        { name: "Kinases", mode: "kinase" },
      ],
      showPopper: false,
      popperInfo: {},
      serviceTime: this.$store.getters.getServicePredictTimes,
    };
  },
  components: {
    "predict-popper": popper,
  },
  methods: {
    setmode(data) {
      this.mode = data;
    },
    ifinput() {
      if (this.molecularFormula != "") this.ifshow = 0;
      else this.ifshow = 1;
    },
    uploadfile() {
      document.getElementById("uploadinput").click();
    },
    gotosingle() {
      this.$router.replace("/service/activity-prediction/single");
    },
    submitform(e) {
      //处理上传的分子式文档
      const file = e.target.files;
      const filename = e.target.files[0]["name"];
      this.filename = filename;
      const format = filename.substr(filename.lastIndexOf(".") + 1);
      if (format == "csv") {
        const fd = new FormData();
        fd.append("file", file[0]);
        this.smilesFile = fd;
        console.log("upload success");
        document.getElementById("mfinput").disabled = "disabled";
        document.getElementById("mfinput").placeholder =
          "Have uploaded the file. You cannot enter or refresh the page, The name of the file is " +
          this.filename;
        document.getElementById("mfinput").style =
          "font-weight:bold;background:white;color:white";
        this.predictmode = 1;
      } else {
        this.$store.commit("alertMsg", {
          msg: "Please upload a file in CSV format!",
          type: "error",
        });
      }
    },
    checkForm() {
      //检查登陆
      if (!this.$store.getters.getToken) {
        this.$store.commit("alertMsg", {
          msg: "Please login first!",
          type: "error",
        });
        this.$router.push({
          path: "/account/sign-in",
          query: { redirect: this.$route.path },
        });
        return false;
      }
      //检查输入是否为空

      if (
        !this.smilesFile &&
        (!this.molecularFormula ||
          this.molecularFormula == "" ||
          this.molecularFormula.trim() == "")
      ) {
        this.$store.commit("alertMsg", {
          msg: "Enter molecular formula or upload a csv file.",
          type: "error",
        });
        return false;
      }
      return true;
    },
    getSmileInfo() {
      BatchApi.getSmileInfo({ smiles: this.molecularFormula }) //先获取分子信息（直接输入分子式）
        .then((res) => {
          if (res.code == 200) {
            console.log("获取分子信息", res.data);
            this.smilesinfo = res.data;
          }
        });
    },
    getSmileInfoByFile() {
      BatchApi.getSmileInfoWithFile(this.smilesFile) //先获取分子信息（输入文档）
        .then((res) => {
          if (res.code == 200) {
            this.smilesinfo = res.data;
          }
        });
    },
    checkResponse(res) {
      if (res.code == 200) {
        this.$store.commit("alertMsg", {
          msg: "Submit sucessfully.",
          type: "ok",
        });
        // 重定向到历史结果页
        setTimeout(() => {
          this.$router.push({
            path: "/user/activity_history",
          });
        }, 1200);
      } else if (res.code == 401) {
        this.$store.commit("alertMsg", {
          msg: "Please login first!",
          type: "error",
        });
        this.$router.push({
          path: "/account/sign-in",
          query: { redirect: this.$route.path },
        });
      } else {
        this.$store.commit("alertMsg", {
          msg: res.msg,
          type: "error",
        });
      }
    },
    getModelInfo() {
      BatchApi.getModelInfo({ mode: this.mode }).then((res) => {
        //获取成功后进一步获取模型的信息
        if (res.code == 200) {
          console.log("获取模型信息", res.data);
          this.modelinfo = res.data;
        }
      });
    },
    getScore() {
      BatchApi.getActivityScore({
        mode: this.mode,
        smiles: this.molecularFormula,
      })
        .then((res) => {
          //获取成功后进一步获取模型预测分数
          this.checkResponse(res);
        })
        .catch((err) => {
          this.$store.commit("alertMsg", {
            msg: err,
            type: "error",
          });
        });
    },
    getScoreByFile() {
      BatchApi.getBatchActivityScore({
        mode: this.mode,
        file: this.smilesFile,
      })
        .then((res) => {
          //获取成功后进一步获取模型预测分数
          this.checkResponse(res);
        })
        .catch((err) => {
          this.$store.commit("alertMsg", {
            msg: err,
            type: "error",
          });
        });
    },
    batchpredict() {
      if (this.predictmode == 0) {
        this.getScore();
      } else {
        this.getScoreByFile();
      }
    },
    onSubmit() {
      if (this.checkForm()) {
        this.popperInfo = {
          Smiles: this.molecularFormula,
          Classification: this.getModeName(this.mode),
          "Available Time": this.serviceTime,
        };
        this.showPopper = true;
      }
    },
    predict() {
      if (this.predictmode == 0) this.getScore();
      else this.getScoreByFile();
    },
    onSubmitPopper() {
      this.predict();
      this.showPopper = false;
    },
    onCancelPopper() {
      this.showPopper = false;
      console.log("oncancel");
    },
    downloadExample() {
      window.open(this.$store.state.baseUrl + "/media/files/Batch_Example.csv");
    },
    getModeName(mode) {
      for (let i = 0; i < this.modeArr.length; i++) {
        if (this.modeArr[i].mode == mode) return this.modeArr[i].name;
      }
    },
  },
};
</script>

<style scoped>
.columncenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rowcenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.backgroundimg {
  z-index: 1;
  position: absolute;
  left: 50%;
  width: 70%;
  transform: translateX(-50%);
}

.background {
  /* background: linear-gradient(var(--color1-dark), var(--color1-light)); */
  height: 100vh;
  background-size: 100% 100%;
}

.radios {
  display: flex;
  flex-wrap: wrap;
  width: 615px;
  justify-content: space-around;
}

.input {
  margin-top: 20px;
  width: 30%;
}

input[type="radio"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

input[type="radio"]+label::before {
  content: "\a0";
  /*不换行空格*/
  display: inline-block;
  vertical-align: 0.2em;
  width: 14px;
  height: 14px;
  margin-right: 19px;
  border-radius: 3px;
  background-color: whitesmoke;
  text-indent: 0.15em;
  line-height: 0.65;
  /*行高不加单位，子元素将继承数字乘以自身字体尺寸而非父元素行高*/
}

input[type="radio"]:checked+label::before {
  content: "\2713";
  background-color: skyblue;
}

.spacebewteen {
  width: 100%;
  display: flex;
  /* background: pink; */
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.input {
  width: 200px;
}

.input1 {
  font-size: 14pt;
  margin-top: 38px;
  width: 577px;
  outline: none;
  height: 208px;
  padding-bottom: 19px;
  padding: 19px;
  border-radius: 10px;
}

.button1 {
  margin-top: 22px;
  width: 327.5px;
  height: 47px;
  color: black;
  font-weight: bold;
  font-size: 20pt;
  border: none;
  border-radius: 10px;
  background: linear-gradient(#e8f4ff, #8bbbed);
}

.button {
  margin-top: 22px;
  width: 327.5px;
  height: 47px;
  color: black;
  font-weight: bold;
  font-size: 20pt;
  border: none;
  border-radius: 10px;
  background: linear-gradient(#8bbbed, #e8f4ff);
}

.text {
  font-size: 14pt;
  color: white;
}

.text-area {
  position: relative;
}

.text-area-btn {
  position: absolute;
  right: 0px;
  color: gray;
  bottom: 9px;
}
</style>
