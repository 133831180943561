<template>
  <div class="activity-prediciton">
    <Batchinput></Batchinput>
  </div>
</template>

<script>
import Batchinput from "../components/batchinput.vue";

export default {
  name: "batch-prediction",
  data() {
    return {};
  },
  methods: {},
  components: {
    Batchinput,
  },
};
</script>

<style scoped>
h1 {
  color: white;
}
</style>
