import request from "../request"

export default {
    getData:(data) =>{
        return request.testpost({
            url: '/link/_search',
            data:data
        })
    }
    
}