import store from '../api/store'

var baseUrl = store.state.baseUrl;

function request(params) {
    let url = baseUrl + params.url;
    let config = {
        mode: 'cors',
        method: params.method,
        headers: {
            'AUTHORIZATION': store.state.token,
            'Content-Type': 'application/json',
        }
    };
    if (params.method == 'POST' || params.method == 'PUT') {
        config.body = JSON.stringify(params.data);
    }
    store.commit('startLoading');
    return fetch(url, config).then(res => {
        return res.json();
    }).finally(() => store.commit('endLoading'));
}

function testrequest(params) {
    let url = store.state.databaseUrl + params.url;
    let config = {
        mode: 'cors',
        method: params.method,
        headers: {
            'Content-Type': 'application/json',
            'AUTHORIZATION': 'ApiKey Z05aNzk0RUJtTTg3VnZTMXZyNHM6b2xGOS02RzVRaXFOamtVX2NIeVBPQQ==',
        }
    };
    if (params.method == 'POST' || params.method == 'PUT') {
        config.body = JSON.stringify(params.data);
    }
    return fetch(url, config).then(res => {
        return res.json();
    });
}

function filerequest(params) {
    let url = baseUrl + params.url;
    let config = {
        mode: 'cors',
        method: params.method,
        headers: {
            'AUTHORIZATION': store.state.token,
            // 'Content-Type': 'multipart/form-data',
        },
        body: params.data
    };
    return fetch(url, config).then(res => res.json());
}

export default {
    get(params) {
        return request({
            url: params.url,
            method: 'GET'
        });
    },
    post(params) {
        return request({
            url: params.url,
            method: 'POST',
            data: params.data
        });
    },
    testpost(params) {
        return testrequest({
            url: params.url,
            method: 'POST',
            data: params.data
        });
    },
    testget(params) {
        return testrequest({
            url: params.url,
            method: 'GET',
        });
    },
    filepost(params) {
        return filerequest({
            url: params.url,
            method: 'POST',
            data: params.data
        });
    },
    put(params) {
        return request({
            url: params.url,
            method: 'PUT',
            data: params.data
        });
    }
}