import request from "../request"

export default {
    getModeInfo: (data) => {
        return request.get({
            url: '/service/model_info?mode='+data.mode,
            data: data,
        })
    },

    getSmileInfo: (data) => {
        return request.post({
            url: '/service/basic_info',
            data: data,
        })
    },

    getSmileInfoWithFile: (data) => {
        return request.filepost({
            url: '/service/basic_info',
            data: data,
        })
    },

    getActivityScore: (data) => {
        return request.post({
            url: '/service/predict_without_file/'+data.mode,
            data: data,
        })
    },
    getBatchActivityScore: (data) =>{
        return request.filepost({
            url: '/service/predict_with_file/'+data.mode,
            data: data.file,
        })
    },
}