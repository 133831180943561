import request from "../request";

export default {
    login: (data) => {
        return request.post({
            url: '/user/login_with_email',
            data: data,
        })
    },
    register: (data) => {
        return request.post({
            url: '/user/register',
            data: data,
        })
    },
    register_check: (email, nickname) => {
        let path = `/user/register_check?email=${email}`;
        if (nickname) path += `&nickname=${nickname}`;
        return request.get({
            url: path,
        })
    },
    sendCode: (email) => {
        return request.get({
            url: `/user/send_email?email=${email}`,
        })
    },
    getInfo: (token) => {
        return request.get({
            url: `/user/info?AUTHORIZATION=${token}`,
        })
    },
    upadteInfo: (username, userInfo) => {
        return request.put({
            url: `/user/${username}/updateInfo?`,
            data: userInfo,
        })
    },
    uploadAvatar: (formdata) => {
        return request.filepost({
            url: '/user/upload_avatar',
            data: formdata,
        })
    },
    changePwd: (data) => {
        return request.post({
            url: '/user/password',
            data: data,
        })
    },
    findPwd: (data) => {
        return request.post({
            url: '/user/find_password',
            data: data,
        })
    }
}