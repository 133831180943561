import request from "../request"

export default {
    sumbitGeneration(data) {
        return request.post({
            url: '/generation/',
            data: {
                smiles: data.smiles,
                title: data.title,
                target: data.target,
            },
        })
    }
}